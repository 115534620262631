import React, { useState } from 'react';
import './stepper.scss';

const Stepper = ({ setActive, active, method }) => {
  return (
    <div className="row stepper-container">
      <div className="col-md-3" onClick={() => setActive(1)}>
        <div className={`single-stepper ${active === 0 ? 'active' : ''}`}>
          <span>1.</span>
          <span className="title">Method</span>
        </div>
      </div>
      <div className="col-md-3" onClick={() => setActive(1)}>
        <div className={`single-stepper ${active === 1 ? 'active' : ''}`}>
          <span>2.</span>
          <span className="title">{method === 'csv' ? 'Upload' : 'Search'}</span>
        </div>
      </div>
      {method !== 'csv' &&
        <div className="col-md-3" onClick={() => setActive(2)}>
          <div className={`single-stepper ${active === 2 ? 'active' : ''}`}>
            <span>3.</span>
            <span className="title">Choose ISP</span>
          </div>
        </div>
      }
      <div className="col-md-3" onClick={() => setActive(3)}>
        <div className={`single-stepper ${active === 3 ? 'active' : ''}`}>
          <span>{method === 'csv' ? '.3' : '4.'}</span>
          <span className="title">Generate</span>
        </div>
      </div>
    </div >
  )
}

export default Stepper;
