import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import Logo from '../../Assets/Images/logo.png';
import './Search.scss';
import Loading from '../../Common/Loading/index';
import _ from 'lodash';
import * as turf from '@turf/turf';
import Modal from './Import'
import Papa from 'papaparse';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// mapboxgl.workerClass = MapboxWorker;
// mapboxgl.accessToken = 'pk.eyJ1IjoiamhhbW1vbmQyMDEyIiwiYSI6ImNrbWNmbmx3bDA1aG8ycG13bzFhbDZqNmYifQ.kiAnA5FfUIoUFDSgq5Uyxw';

function Search() {
  const { register, handleSubmit, getValues, watch, errors } = useForm();
  const [results, setResults] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [loadingText, setLoadingText] = useState(false);
  const [fetchedGeoJSON, setGeoJSON] = useState({});
  const [active, setActive] = useState({});
  const [codes, setCodes] = useState([]);
  const [drawnFeatures, setDrawnFeatures] = useState([]);
  const [generated, setGenerated] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [map, setMap] = useState(null);
  const [csvFile, setCsvFile] = useState(null);
  const [modal, setModal] = useState(false);

  const onSubmit = data => console.log(data);

  const mapContainer = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiamhhbW1vbmQyMDEyIiwiYSI6ImNqZzN2dWNucjBzY2YycmxqNXJ2OXhqaWkifQ.VBkIehZxM1wrXTNmqIXfkQ';
    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/satellite-v9", // stylesheet location
        center: [-94.401622, 45.813486],
        zoom: 7
      });

      map.on("load", () => {
        setMap(map);
        map.resize();

        map.addSource("blocks", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: []
          }
        });

        map.addLayer({
          id: "blocks-fill",
          source: "blocks",
          type: "fill",

          paint: {
            "fill-color": "green",
            "fill-opacity": 0.5,
            "fill-outline-color": "white"
          }
        });

        map.on("click", "blocks-fill", (e) => {
          const p = e.features[0].properties;
          const message =
            `<b>Block Code ${p.GEOID}</b>`;

          new mapboxgl.Popup()
            .setHTML(message)
            .setLngLat(e.lngLat)
            .addTo(map);

        });
      });
    };

    if (!map) initializeMap({ setMap, mapContainer });
  }, []);

  const styles = {
    width: "100%",
    height: "500px",
    marginTop: "20px"
  };

  const searchForItems = () => {
    const searchTerm = getValues("searchTerm").toUpperCase();
    const state = getValues("state").toUpperCase();
    setSearching(true);
    setLoadingText('We are fetching your results, this could take a minute or two.')
    // update here when change is made to data source
    axios.get(`https://opendata.fcc.gov/resource/whue-6pnt.json?$$app_token=HPSjJTvtyIx0RcK15fd2hcxJS&stateabbr=${state}&$limit=10000&$where=upper(providername) LIKE '%25${searchTerm}%25'`).then(res => {
      if (res.data) {
        let results = _.groupBy(res.data, 'providername')
        results = Object.entries(results).map(i => ({ ...i[1][0], codes: i[1].map(c => String(c.blockcode)) }))
        setResults(results);
      }
      setSearching(false);
      if (res.data.length < 1) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    });
  };

  let count = 0;
  const setProgress = (requestSize) => {
    setGenerated((50 / requestSize) * count++)
  }
  const onGenerate = async () => {
    setSearching(true);
    setLoadingText('Mapping your data, this could take a minute or two.')
    setIsGenerating(true)
    let codeIds = codes.map(d => String(d.blockcode))
    fetchFeatures(codeIds, codes)
  }

  const onAddToCurrentView = () => {
    const canvas = map.getCanvas()
    const w = canvas.width
    const h = canvas.height
    const cUL = map.unproject([0, 0]).toArray()
    const cUR = map.unproject([w, 0]).toArray()
    const cLR = map.unproject([w, h]).toArray()
    const cLL = map.unproject([0, h]).toArray()
    const coordinates = [cUL, cUR, cLR, cLL, cUL]
    let geometry = {
      coordinates: [coordinates],
      type: "Polygon"
    }
    geometry = window.Terraformer.geojsonToArcGIS(geometry);

    executeQuery(geometry, 'esriGeometryPolygon')
  }

  const fetchFeatures = async (codeIds, Codes) => {
    let chunked = []
    let size = 50;
    Array.from({ length: Math.ceil(codeIds.length / size) }, (val, i) => {
      chunked.push(codeIds.slice(i * size, i * size + size))
    })
    let responses = await Promise.all(chunked.map(chunk => axios.post(`https://geo-json.herokuapp.com/shapes`, {
      ids: chunk
    }, {
      onDownloadProgress: () => setProgress(chunked.length),
    })))

    responses = responses.reduce((acc, cur) => [...acc, ...cur.data.features], [])
    setGenerated(0);
    count = 0;
    setIsGenerating(false)
    let formatedGeoJSon = {
      "type": "FeatureCollection",
      features: Codes.filter(f => f.blockcode).map((f, i) => ({ ...(responses.find(c => f.blockcode === c.geometry.geoid)), properties: f, }))
    }
    setGeoJSON(formatedGeoJSon);

    map.addSource('generated-geojson-data', {
      type: 'geojson',
      data: formatedGeoJSon,
    });

    map.addLayer({
      id: 'generated-geojson-layer',
      source: 'generated-geojson-data',
      type: 'fill',
      layout: {},
      paint: {
        'fill-color': '#286b7e',
        'fill-opacity': 0.4,
      }
    });

    var bbox = turf.bbox({
      type: 'FeatureCollection',
      features: formatedGeoJSon.features
    });

    map.fitBounds([
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]]
    ], { padding: 0 });
    setSearching(false);
    setLoadingText('')

    setTimeout(() => {
      onAddToCurrentView();
    }, 1000);
  }

  const downloadGeoJSON = async () => {
    setSearching(true);
    setLoadingText('Mapping your data, this could take a minute or two.');

    const fileName = String((new Date()).getTime());
    const json = JSON.stringify(fetchedGeoJSON);
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".geojson";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setSearching(false);
    setLoadingText('')
  }

  const renderMap = (data) => {
    if (fetchedGeoJSON && fetchedGeoJSON.features && !fetchedGeoJSON.features.length) {
      toast.error("No features found!")
      return
    }

    const Draw = new MapboxDraw({
      controls: {
        point: false,
        line_string: false,
        polygon: true,
        trash: true,
        combine_features: false,
        uncombine_features: false
      }
    });

    const updateArea = (e) => {
      const data = Draw.getAll();
      setDrawnFeatures([...drawnFeatures, ...data.features])
      const feature = e.features[0];
      // map.getSource("query").setData(feature);
      // Draw.deleteAll();

      const geometry = window.Terraformer.geojsonToArcGIS(feature.geometry);
      let geometryType = "esriGeometry" + feature.geometry.type;
      if (feature.geometry.type === "LineString") {
        geometryType = "esriGeometryPolyline";
      }
      // executeQuery(geometry, geometryType);
    }

    map.on('draw.create', updateArea);
    map.on('draw.delete', updateArea);
    map.on('draw.update', updateArea);

    map.addControl(Draw, 'top-left');

    map.on('click', 'generated-geojson-layer', function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice();

      const logrecno = e.features[0].properties.logrecno;
      const provider_id = e.features[0].properties.provider_id;
      const frn = e.features[0].properties.frn;
      const providername = e.features[0].properties.providername;
      const dbaname = e.features[0].properties.dbaname;
      const holdingcompanyname = e.features[0].properties.holdingcompanyname;
      const hoconum = e.features[0].properties.hoconum;
      const hocofinal = e.features[0].properties.hocofinal;
      const stateabbr = e.features[0].properties.stateabbr;
      const blockcode = e.features[0].properties.blockcode;
      const techcode = e.features[0].properties.techcode;
      const consumer = e.features[0].properties.consumer;
      const maxaddown = e.features[0].properties.maxaddown;
      const maxadup = e.features[0].properties.maxadup;
      const business = e.features[0].properties.business;

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup({
        className: 'popup-visibility'
      })
        .setLngLat([e.lngLat.lng, e.lngLat.lat])
        .setHTML(
          '<div class="pop-holder">'
          + '<div class="single-item><strong>Logrecno: </strong>' + logrecno + '</div>'
          + '<div class="single-item><strong>Provider ID: </strong>' + provider_id + '</div>'
          + '<div class="single-item><strong>Frn: </strong>' + frn + '</div>'
          + '<div class="single-item><strong>Provider Name: </strong>' + providername + '</div>'
          + '<div class="single-item><strong>DBA Name: </strong>' + dbaname + '</div>'
          + '<div class="single-item><strong>Holding Company Name: </strong>' + holdingcompanyname + '</div>'
          + '<div class="single-item><strong>HOCO Num: </strong>' + hoconum + '</div>'
          + '<div class="single-item><strong>HOCO Final: </strong>' + hocofinal + '</div>'
          + '<div class="single-item><strong>State Abbr: </strong>' + stateabbr + '</div>'
          + '<div class="single-item><strong>Block Code: </strong>' + blockcode + '</div>'
          + '<div class="single-item><strong>Tech Code: </strong>' + techcode + '</div>'
          + '<div class="single-item><strong>Consumer: </strong>' + consumer + '</div>'
          + '<div class="single-item><strong>Max Ad Down: </strong>' + maxaddown + '</div>'
          + '<div class="single-item><strong>Max Ad Up: </strong>' + maxadup + '</div>'
          + '<div class="single-item><strong>Business: </strong>' + business + '</div>'
          + '</div>'
        )
        .addTo(map);
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on('mouseenter', 'generated-geojson-layer', function () {
      map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', 'generated-geojson-layer', function () {
      map.getCanvas().style.cursor = '';
    });

    return () => map.remove();
  }

  useEffect(() => {
    if (Object.keys(fetchedGeoJSON).length > 0) {
      renderMap(fetchedGeoJSON);
    }
  }, [fetchedGeoJSON]);



  const onSelectProvider = (item) => {
    setActive(item)
    setSearching(true);
    setLoadingText('We are fetching your results for entire, this could take a minute or two.')
    // update here when change is made to data source
    axios.get(`https://opendata.fcc.gov/resource/whue-6pnt.json?$limit=10000&$$app_token=HPSjJTvtyIx0RcK15fd2hcxJS&$where=provider_id = '${item.provider_id}'`).then(res => {
      if (res.data) {
        setCodes(res.data)
        setSearching(false);
        setLoadingText('')
      }
    });
  }
  const onDownloadDrawnFeature = async () => {
    let formatedGeoJSon = {
      "type": "FeatureCollection",
      features: drawnFeatures
    }
    const fileName = String((new Date()).getTime());
    const json = JSON.stringify(formatedGeoJSon);
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".geojson";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDrawnFeatures([])
  }
  const onFileChange = (e) => {
    setCsvFile(e.target.files[0])
  }
  const onImmport = () => {
    console.log('HERE HA')
    toggle()
    if (csvFile)
      Papa.parse(csvFile, {
        header: true,
        complete: function (results) {
          setResults(results.data)
          setCodes(results.data)
          setSearching(true);
          setLoadingText('Mapping your data, this could take a minute or two.')
          setIsGenerating(true)
          let codeIds = results.data.map(d => String(d.blockcode))
          fetchFeatures(codeIds, results.data)
        }
      });
  }
  const toggle = () => setModal(!modal)
  const executeQuery = (geometry, geometryType) => {
    window.arcgisRest
      .queryFeatures({
        url: "https://tigerweb.geo.census.gov/arcgis/rest/services/TIGERweb/tigerWMS_Census2010/MapServer/18",
        geometry: geometry,
        geometryType: geometryType,
        spatialRel: "esriSpatialRelIntersects",
        f: "geojson",
        returnGeometry: true,
        outFields: ["GEOID"]
      })

      .then((response) => {
        map.getSource("blocks").setData(response);
        setSearching(false);
        setLoadingText('')
      });
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col text-center mt-5 mb-5">
          <img src={Logo} alt="CNS" width="200" />
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-3">
            <select name="state" className="form-control w-100" ref={register} aria-label="Select a state" defaultValue="AAA">
              <option value="AAA">Select a state</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>

          <div className="col-5">
            <input name="searchTerm" className="form-control w-100" placeholder="Search Term..." ref={register} />
          </div>

          <div className="col-4 d-flex">
            <button className="w-50 btn btn-primary" onClick={searchForItems}>Submit</button>
            <button className="w-50 btn btn-primary" onClick={toggle}>Import CSV</button>
          </div>
        </div>
      </form>

      <div className="row">
        <div className="col">
          <div ref={el => (mapContainer.current = el)} style={styles} />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="panel panel-primary mt-4" id="result_panel">
            {results && results.length ? (
              <>
                <div className="panel-heading">
                  <h3 className="panel-title">Results</h3>
                  <p>Please select a provider below and click generate GeoJSON</p>
                </div>

                <div className="panel-body">
                  <ul className="list-group">
                    {results && results.map(item => (
                      <li className={`list-group-item ${active.providername === item.providername ? 'active' : ''}`} onClick={() => onSelectProvider(item)}>{item.providername}</li>
                    ))}
                  </ul>
                </div>
              </>
            ) : null}

            {noResults && (
              <h3>No Results Found</h3>
            )}
          </div>
        </div>
      </div>

      {results && results.length ? (
        <div className="row mb-5">
          <div className="col-md-4">
            <button className="btn btn-primary w-100" disabled={!active.codes} onClick={onGenerate}>Generate GeoJSON</button>
          </div>

          <div className="col-md-4">
            <button className="btn btn-secondary w-100" disabled={Object.keys(fetchedGeoJSON).length < 1} onClick={downloadGeoJSON}>Download JeoJSON</button>
          </div>

          <div className="col-md-4">
            <button className="btn btn-info w-100" disabled={Object.keys(fetchedGeoJSON).length < 1} onClick={onDownloadDrawnFeature}>Download Drawn Feature</button>
          </div>
        </div>
      ) : null}
      <div className="col-md-4">
        <button className="btn btn-info w-100" onClick={onAddToCurrentView}>Add to current view</button>
      </div>
      {isSearching && <Loading message={loadingText} progress={generated} isGenerating={isGenerating} />}
      <Modal
        onChange={onFileChange}
        onImmport={onImmport}
        modal={modal}
        toggle={toggle}
      />
      <ToastContainer />
    </div>
  );
}

export default Search;
