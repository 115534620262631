import React, { useState } from 'react';

const ProviderList = ({ setProviderSelected, providerSelected, providers, onSelectProvider }) => {
    return (
        <>
            <ul className="list-container">
                {
                    providers.map(provider => (
                        <li className={`${(providerSelected && providerSelected.provider_id) === provider.provider_id ? 'active' : ''}`} onClick={() => setProviderSelected(provider)}>
                            <div>
                                <p className="provider-title">{provider.providername}</p>
                                <span className="provider-id">Provider ID: {provider.provider_id}</span>
                            </div>
                        </li>
                    ))
                }
            </ul>
            { providerSelected && <button className="continue-btn" onClick={() => onSelectProvider(providerSelected)}>Continue <i className="fa fa-arrow-right icon ml-2" ></i></button>}
        </>
    )
}

export default ProviderList;
