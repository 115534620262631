import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const Billing = (props) => {

    return (
        <Modal isOpen={props.modal} toggle={props.toggle} className="confirmation">
            <ModalBody >
                <p>The database has returned more than 10,000 results. Please contact us for a custom quote for requests larger than this.</p>
                <button onClick={props.toggle}>Close</button>
            </ModalBody>

        </Modal >
    );
}

export default withRouter(Billing);