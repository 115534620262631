import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const NoIntersection = (props) => {

    return (
        <Modal isOpen={props.modal} toggle={props.toggle} className="confirmation">
            <ModalBody >
                <p>No intersecting shapes to download.</p>
                <button onClick={props.toggle}>Close</button>
            </ModalBody>

        </Modal >
    );
}

export default withRouter(NoIntersection);