import React, { useState, useEffect } from 'react';
import Stepper from '../../Common/Stepper/index';
import Search from './Search';
import Upload from './Upload';
import ProviderList from './ProviderList';
import './index.scss';
import _ from 'lodash';
import axios from 'axios';
import Loading from '../../Common/Loading/index';
import Map from './Map';
import * as turf from '@turf/turf';
import Papa from 'papaparse';
import BillingModal from './Billing';
import Logo from '../../Assets/Images/logo.svg';
import ImportCSV from './Import';
import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Checkout from '../Checkout/index';
import Confirmation from './Confirmation';
import MoreResultModal from './MoreResult';
import NoIntersection from './NoIntersection';
import tokml from "geojson-to-kml";
import alasql from 'alasql';
import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoiamhhbW1vbmQyMDEyIiwiYSI6ImNqZzN2dWNucjBzY2YycmxqNXJ2OXhqaWkifQ.VBkIehZxM1wrXTNmqIXfkQ';


const Home = ({ history }) => {
  const [file, setFile] = useState(null);
  const [state, setState] = useState('');
  const [providerSearch, setProviderSearch] = useState('');
  const [providerSelected, setProviderSelected] = useState(null);
  const [isSearching, setSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [loadingText, setLoadingText] = useState(false);
  const [fetchedGeoJSON, setGeoJSON] = useState({});
  const [active, setActive] = useState(0);
  const [codes, setCodes] = useState([]);
  const [drawnFeatures, setDrawnFeatures] = useState(null);
  const [generated, setGenerated] = useState(0);
  const [isGenerating, setIsGenerating] = useState(false);
  const [map, setMap] = useState(null);
  const [billingModal, setBillingModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentToken, setPaymentToken] = useState(false);
  const [totalAmount, setTotalAmount] = useState(null);
  const [transactionHash, setTransactionHash] = useState(null);
  const [censusDataAdded, setCensusDataAdded] = useState(false);
  const [studyDataAdded, setStudyDataAdded] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [moreResultsModal, setMoreResultsModal] = useState(false);
  const [theDraw, setTheDraw] = useState(null);
  const [fieldErrors, setFieldErrors] = useState([]);
  const [s3File, setS3File] = useState(null);
  const [hasStudyDataError, setHasStudyDataError] = useState(false);
  const [hasIntersections, setHasIntersections] = useState(false);
  const [blockLayerData, setBlockLayerData] = useState(null);
  const [customCsvLayer, setCustomCsvLayer] = useState({});
  const [couponApplied, setCouponApplied] = useState(false);
  const [method, setMethod] = useState('');
  // it maps the raw json from the csv to feature json
  const getMappedJson = (data) => {
    let mapped = data.map(c => ({
      logrecno: c['LogRecNo'],
      provider_id: c['Provider Id'],
      frn: c['FRN'],
      providername: c['Provider Name'],
      dbaname: c['DBA name'],
      holdingcompanyname: c['Holding company name'],
      hoconum: c['HocoNum'],
      hocofinal: c['HocoFinal'],
      stateabbr: c['StateAbbr'],
      blockcode: c['BlockCode'],
      techcode: c['TechCode'],
      consumer: c['Consumer'],
      maxaddown: c['MaxAddDown'],
      maxadup: c['MaxAdUp'],
      business: c['Business']
    }))
    return mapped;
  }
  const onUploadCSV = (e) => {
    setFile(e.target.files[0])
    Papa.parse(e.target.files[0], {
      header: true,
      complete: function (results) {
        if (results.data.length === 10000) {
          setMoreResultsModal(true)
          return
        }
        setActive(3)
        let mappedJson = getMappedJson(results.data)
        setResults(mappedJson)
        setCodes(mappedJson)
        setSearching(true);
      }
    });
  }
  const onSearchInputChange = (e) => {
    if (e.target.name === 'provider') {
      setProviderSearch(e.target.value)
      setFieldErrors(fieldErrors.filter(f => f !== 'provider'))
    } else {
      setFieldErrors(fieldErrors.filter(f => f !== 'state'))
      setState(e.target.value)
    }
  }
  const searchForItems = () => {
    if (!state) {
      setFieldErrors([...fieldErrors, 'state'])
      return
    }
    if (!providerSearch) {
      setFieldErrors([...fieldErrors, 'provider'])
      return
    }
    setSearching(true);
    setLoadingText('We are fetching your results, this could take a minute or two.')
    // update here when change is made to data source
    axios.get(`https://opendata.fcc.gov/resource/4kuc-phrr.json?$$app_token=HPSjJTvtyIx0RcK15fd2hcxJS&stateabbr=${state}&$limit=10000&$where=upper(providername) LIKE '%25${providerSearch.toLocaleUpperCase()}%25'`).then(res => {
      if (res.data) {
        let results = _.groupBy(res.data, 'provider_id')
        results = Object.entries(results).map(i => ({ ...i[1][0], codes: i[1].map(c => String(c.blockcode)) }))
        setResults(results);
        setActive(active + 1)
      }
      setSearching(false);
      if (res.data.length < 1) {
        setNoResults(true);
      } else {
        setNoResults(false);
      }
    });
  };
  const fetchFeatures = async (codeIds, Codes, isCsvLayer) => {
    //if dev, skipp fetching data
    if (localStorage.getItem('dev_data')) {
      setGeoJSON(JSON.parse(localStorage.getItem('dev_data')));
    } else {
      let chunked = []
      let size = 50;
      Array.from({ length: Math.ceil(codeIds.length / size) }, (val, i) => {
        chunked.push(codeIds.slice(i * size, i * size + size))
      })
      let responses = await Promise.all(chunked.map(chunk => axios.post(`https://geo-json.herokuapp.com/shapes`, {
        ids: chunk
      }, {
        onDownloadProgress: () => setProgress(chunked.length),
      })))

      responses = responses.reduce((acc, cur) => [...acc, ...cur.data.features], [])
      setGenerated(0);
      count = 0;
      setIsGenerating(false)
      setSearching(false);
      setLoadingText('')
      let formatedGeoJSon = {
        "type": "FeatureCollection",
        features: Codes.filter(f => f.blockcode).map((f, i) => ({ ...(responses.find(c => f.blockcode === c.geometry.geoid)), properties: f, }))
      }
      if (isCsvLayer) {
        setCustomCsvLayer(formatedGeoJSon)
      } else {
        setGeoJSON(formatedGeoJSon);
      }
      if (!paymentToken) {
        setBillingModal(true)
      }
    }
  }
  const setBg = (techcode) => {
    const colors = {
      '10': '#73DFFF',
      '11': '#00C5FF',
      '12': '#00A9E6',
      '20': '#73B2FF',
      '30': '#0070FF',
      '40': '#FFD37F',
      '41': '#FFAA00',
      '42': '#E69800',
      '50': '#4CE600',
      '60': '#FF7F7F',
      '70': '#FF0000',
      '90': '#FF73DF',
    }
    if (colors.hasOwnProperty(techcode)) {
      return colors[techcode]
    } else {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      return "#" + randomColor;
    }
  }
  useEffect(() => {
    if (map && Object.keys(fetchedGeoJSON).length > 0) {
      // groupBy by tech code and create seperate colored layer
      Object.entries(_.groupBy(fetchedGeoJSON.features, (each) => each.properties.techcode)).forEach(([techcode, features], index) => {
        if (map.getSource(`generated-geojson-data${techcode}`)) {
          map.getSource(`generated-geojson-data${techcode}`).setData({
            "type": "FeatureCollection",
            features: features
          });
        } else {
          map.addSource(`generated-geojson-data${techcode}`, {
            type: 'geojson',
            data: {
              type: "FeatureCollection",
              features,
            },
          });
          map.addLayer({
            id: `generated-geojson-layer${techcode}`,
            source: `generated-geojson-data${techcode}`,
            type: 'fill',
            layout: {},
            paint: {
              'fill-color': setBg(techcode),
              'fill-opacity': 0.75,
            }
          });
        }
      })
      var bbox = turf.bbox({
        type: 'FeatureCollection',
        features: fetchedGeoJSON.features
      });
      map.fitBounds([
        [bbox[0], bbox[1]],
        [bbox[2], bbox[3]]
      ], { padding: 0 });
      setSearching(false);
      setLoadingText('')
    }
  }, [map, fetchedGeoJSON])

  // renders custom csv layer
  useEffect(() => {
    if (map && Object.keys(customCsvLayer).length > 0) {
      if (map.getSource(`custom-csv-data`)) {
        map.getSource(`custom-csv-data`).setData({
          "type": "FeatureCollection",
          features: customCsvLayer.features
        });
      } else {
        map.addSource(`custom-csv-data`, {
          type: 'geojson',
          data: {
            type: "FeatureCollection",
            features: customCsvLayer.features,
          },
        });
        map.addLayer({
          id: `custom-csv-layer`,
          source: `custom-csv-data`,
          type: 'fill',
          layout: {},
          paint: {
            'fill-color': '#ce16db',
            'fill-opacity': 0.4,
          }
        });
      }
      map.on("click", "custom-csv-layer", (e) => {
        const logrecno = e.features[0].properties.logrecno;
        const provider_id = e.features[0].properties.provider_id;
        const frn = e.features[0].properties.frn;
        const providername = e.features[0].properties.providername;
        const dbaname = e.features[0].properties.dbaname;
        const hoconum = e.features[0].properties.hoconum;
        const hocofinal = e.features[0].properties.hocofinal;
        const stateabbr = e.features[0].properties.stateabbr;
        const blockcode = e.features[0].properties.blockcode;
        const techcode = e.features[0].properties.techcode;
        const consumer = e.features[0].properties.consumer;
        const maxaddown = e.features[0].properties.maxaddown;
        const maxadup = e.features[0].properties.maxadup;
        const business = e.features[0].properties.business;
        const holdingcompanyname = e.features[0].properties.holdingcompanyname;

        new mapboxgl.Popup({ focusAfterOpen: false })
          .setHTML('<div class="pop-holder">'
            + '<div class="single-item"><strong>Logrecno: </strong>' + logrecno + '</div>'
            + '<div class="single-item"><strong>Provider ID: </strong>' + provider_id + '</div>'
            + '<div class="single-item"><strong>Frn: </strong>' + frn + '</div>'
            + '<div class="single-item"><strong>Provider Name: </strong>' + providername + '</div>'
            + '<div class="single-item"><strong>DBA Name: </strong>' + dbaname + '</div>'
            + '<div class="single-item"><strong>HOCO Num: </strong>' + hoconum + '</div>'
            + '<div class="single-item"><strong>HOCO Final: </strong>' + hocofinal + '</div>'
            + '<div class="single-item"><strong>Holding Company Name: </strong>' + holdingcompanyname + '</div>'
            + '<div class="single-item"><strong>State Abbr: </strong>' + stateabbr + '</div>'
            + '<div class="single-item"><strong>Block Code: </strong>' + blockcode + '</div>'
            + '<div class="single-item"><strong>Tech Code: </strong>' + techcode + '</div>'
            + '<div class="single-item"><strong>Consumer: </strong>' + consumer + '</div>'
            + '<div class="single-item"><strong>Max Ad Down: </strong>' + maxaddown + '</div>'
            + '<div class="single-item"><strong>Max Ad Up: </strong>' + maxadup + '</div>'
            + '<div class="single-item"><strong>Business: </strong>' + business + '</div>'
            + '</div>')
          .setLngLat(e.lngLat)
          .addTo(map);

      });
      setSearching(false);
      setLoadingText('')
    }
  }, [customCsvLayer])


  const downloadGeoJSON = async () => {
    if (!paymentToken) {
      setBillingModal(true)
      return
    }
    const fileName = String((new Date()).getTime()) + '.geojson';
    const json = JSON.stringify(fetchedGeoJSON);
    const blob = new Blob([json], { type: 'application/geo+json' });
    if (!s3File) {
      var file = new File([blob], fileName, { type: blob.type });
      const uploadUrl = await uploadFile(file)
      setS3File(uploadUrl)
      setSearching(false);
      setLoadingText('')
      setBillingModal(false)
      setPaymentModal(false)
    } else {
      // sendEmail(s3File)
      const href = await URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
  }
  const sendEmail = async (uploadUrl) => {
    const sendEmail = await axios.post(`https://geo-json.herokuapp.com/transactions/email`, {
      id: transactionHash,
      url: uploadUrl
    })
    if (sendEmail.data && sendEmail.data.success) {
      setConfirmationModal(true)
    }
  }
  const uploadFile = async f => {
    const body = {
      file_type: f.type,
      file_name: f.name,
    };

    const url = `https://geo-json.herokuapp.com/s3-upload`;
    const res = await axios.post(url, body);
    const { signedUrl } = res.data;
    const imgUrl = signedUrl.split('?')[0];
    const options = {
      headers: {
        'Content-Type': f.type,
        'Content-Disposition': 'attachment',
      }
    };
    await axios.put(signedUrl, f, options);
    return imgUrl
  };
  const onSelectProvider = (item) => {
    setProviderSelected(item)
    setSearching(true);
    setLoadingText('We are fetching your results for entire, this could take a minute or two.')
    // update here when change is made to data source
    axios.get(`https://opendata.fcc.gov/resource/4kuc-phrr.json?$limit=10000&$$app_token=HPSjJTvtyIx0RcK15fd2hcxJS&$where=provider_id = '${item.provider_id}'`).then(res => {
      if (res.data) {
        setCodes(res.data)
        setSearching(false);
        setLoadingText('')
        if (res.data.length === 10000)
          setMoreResultsModal(true)
        else {
          setActive(active + 1)
        }
      }
    });
  }

  const onDownloadDrawnFeature = async () => {
    // if nothing is drawn, dont take any action
    if (!drawnFeatures) {
      return
    }
    // set unique filename
    const fileName = String((new Date()).getTime());

    const json = JSON.stringify(drawnFeatures);
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".geojson";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // return intersection feature collection
  const getIntersectingFeature = () => {
    // if nothing is drawn, dont take any action
    if (!blockLayerData && drawnFeatures) {
      return
    }

    let boundedFeatures = []
    // loop through drawn features
    //each feature will have an array of array of cordinates
    // if cordinates exists inside the boundary return that feature
    // turf.polygon() -> this checks a point is insides of polygon or not

    drawnFeatures.features.forEach((feature) => {
      boundedFeatures = [...boundedFeatures,
      ...blockLayerData.features.filter(f => {
        return f.geometry.coordinates.some(coordinate => {
          return coordinate.some(f1 => {
            if (f1.length === 2) {
              return turf.booleanPointInPolygon(turf.point(f1), turf.polygon(feature.geometry.coordinates), { ignoreBoundary: true })
            } else {
              // usually a point is defined by a cordinates points which is an array of 2 elements.
              // but getting an array of 115 elements, we ignorin this.
            }
          })
        })
      })
      ]
    })
    if (!boundedFeatures.length) {
      setHasIntersections(true)
      return
    }
    // create FeatureCollection for the filtered features
    let boundedCollection = {
      type: 'FeatureCollection',
      features: boundedFeatures
    }
    return boundedCollection
  }
  const onDownloadIntersectingFeature = async () => {
    let boundedCollection = getIntersectingFeature()
    // set unique filename
    const fileName = String((new Date()).getTime());

    // convert to downloadable json file
    const json = JSON.stringify(boundedCollection);
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".geojson";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const onCSVDownloadIntersectingFeature = async () => {
    let boundedCollection = getIntersectingFeature()
    let mapped = boundedCollection.features.map(c => ({
      'BlockCode': c.properties.GEOID,
    }))
    let filename = `${(new Date()).getTime()}.csv`
    alasql('SELECT * INTO CSV("' + filename + '",{headers:true, separator : ","}) FROM ?', [mapped]);
  }

  // exports intersecting shapes into kml format
  const onExportKMLIntersectingShapes = async () => {
    let boundedCollection = getIntersectingFeature()
    const kml = tokml(boundedCollection);
    let filename = `${(new Date()).getTime()}.kml`
    const blob = new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  let count = 0;
  const setProgress = (requestSize) => {
    setGenerated((50 / requestSize) * count++)
  }
  const onAddToCurrentView = () => {
    setCensusDataAdded(true)
    const canvas = map.getCanvas()
    const w = canvas.width
    const h = canvas.height
    const cUL = map.unproject([0, 0]).toArray()
    const cUR = map.unproject([w, 0]).toArray()
    const cLR = map.unproject([w, h]).toArray()
    const cLL = map.unproject([0, h]).toArray()
    const coordinates = [cUL, cUR, cLR, cLL, cUL]
    let geometry = {
      coordinates: [coordinates],
      type: "Polygon"
    }
    geometry = window.Terraformer.geojsonToArcGIS(geometry);

    executeQuery(geometry, 'esriGeometryPolygon')
  }
  const executeQuery = (geometry, geometryType) => {
    window.arcgisRest
      .queryFeatures({
        url: "https://tigerweb.geo.census.gov/arcgis/rest/services/TIGERweb/tigerWMS_Census2010/MapServer/18",
        geometry: geometry,
        geometryType: geometryType,
        spatialRel: "esriSpatialRelIntersects",
        f: "geojson",
        returnGeometry: true,
        outFields: ["GEOID"]
      })

      .then((response) => {
        setBlockLayerData(response)
        map.getSource("blocks").setData(response);
        setSearching(false);
        setLoadingText('')
        setCensusDataAdded(false)
      });
  }
  // This adds study layer to the map
  const onAddStudyLayer = async () => {
    setHasStudyDataError(false)
    setStudyDataAdded(true)
    const canvas = map.getCanvas()
    const w = canvas.width
    const h = canvas.height
    const cUL = map.unproject([0, 0]).toArray()
    const cUR = map.unproject([w, 0]).toArray()
    const cLR = map.unproject([w, h]).toArray()
    const cLL = map.unproject([0, h]).toArray()
    const coordinates = [cUL, cUR, cLR, cLL, cUL]
    let geometry = {
      coordinates: [coordinates],
      type: "Polygon"
    }
    geometry = window.Terraformer.geojsonToArcGIS(geometry);
    const res = await axios.get(`https://services.arcgis.com/YnOQrIGdN9JGtBh4/arcgis/rest/services/study_areas_public_16sep19/FeatureServer/0/query`, {
      params: {
        geometry: geometry,
        f: 'geojson',
        where: '1=1',
        geometryType: 'esriGeometryPolygon',
        spatialRel: 'esriSpatialRelIntersects',
        returnGeometry: true
      }
    })
    if (res.data.error) {
      setHasStudyDataError(true)
    } else
      map.getSource("study").setData(res.data);
    setStudyDataAdded(false)
  }
  const onGenerate = async () => {
    setSearching(true);
    setLoadingText('Mapping your data, this could take a minute or two.')
    setIsGenerating(true)
    let codeIds = codes.map(d => String(d.blockcode))
    fetchFeatures(codeIds, codes)
  }

  const onBuy = async (amount) => {
    let formatted = parseInt(String(amount).split('.')[0])
    setTotalAmount(formatted)
    try {
      let transaction = await axios.post(`https://geo-json.herokuapp.com/transactions`, {
        no_of_features: codes.length
      })
      if (transaction.data.success) {
        setTransactionHash(transaction.data.hash)
        paymentModalToggle()
      } else {
      }
    } catch (e) {
      console.log(e.response)
    }
  }
  const onApplyCoupon = async (code) => {
    if (!code) return;
    try {
      let coupon = await axios.get(`https://geo-json.herokuapp.com/coupons/${code}`)
      if (coupon.data.success) {
        setCouponApplied(coupon.data)
        toast.success(`Coupon Applied!`)
      }

    } catch (e) {
      console.log(e.response)
      toast.error(e.response.data.message)
    }
  }
  const paymentModalToggle = () => {
    if (paymentModal && !paymentToken) {
      return
    }
    setPaymentModal(!paymentModal)
    setBillingModal(false)
  }
  useEffect(() => {
    if (paymentToken) {
      downloadGeoJSON()
    }
  }, [paymentToken])
  const onPayment = async (data) => {
    setSearching(true);
    setLoadingText('processing payment...')
    let source = data.token && data.token.id
    delete data.token
    try {
      const order = await axios.post('https://geo-json.herokuapp.com/stripe/charge', {
        ...data,
        source,
        hash: transactionHash
      })
      setPaymentToken(order.data.charge.id)
    } catch (e) {
      setSearching(false);
      setLoadingText('')
      console.log(e)
      toast.error(e.response.data.message)
    }
  }
  const onClickSetActive = (active) => {
    if (active === 2 && providerSelected) {
      setActive(active)
    } else if (active === 3 && Object.keys(fetchedGeoJSON).length > 0) {
      setActive(active)
    } else if (active === 1) {
      setActive(active)
    }
  }
  const onChangeBillingModal = () => {
    if (transactionHash) {
      setBillingModal(!billingModal)
    }
  }
  const onUploadCSVLayer = (e) => {
    setSearching(true);
    setLoadingText('Mapping your data, this could take a minute or two.')
    Papa.parse(e.target.files[0], {
      header: true,
      complete: function (results) {
        let mappedJson = getMappedJson(results.data)
        let codeIds = mappedJson.map(d => String(d.blockcode))
        fetchFeatures(codeIds, mappedJson, true)
      }
    });
  }

  const methodSelect = (method) => {
    setMethod(method);
  }

  const goToStepOne = () => {
    if (method) {
      setActive(1);
    } else {
      alert('You must select a method first');
    }
  }
  // exports shapes to shp
  const onExportSHP = async () => {
    setSearching(true);
    setLoadingText('Converting Geojson to Shape file, please wait...')
    var details = {
      'jsonUrl': s3File,
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const convert = await axios.post(`https://ogre.adc4gis.com/convertJson`, formBody, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob'
    })
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(convert.data);
    link.download = `${new Date().getTime()}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setSearching(false);
    setLoadingText('')
  }
  return (
    <>
      <div className={`container ${active === 3 && 'large'}`}>
        {active === 3 ? (
          <>
            <div className='row mt-3'>
              <div className='col-md-2'>
                <div className='logo alt'>
                  <img src={Logo} />
                </div>
              </div>

              <div className='col pt-3'>
                <Stepper
                  active={active}
                  setActive={onClickSetActive}
                  method={method}
                />
                <p className="subheader data-warn text-center mt-1 mb-0">*Census block boundaries are based on 2010 census data, and data queried from the FCC's 477 database is dated June 2020, made public in April 13, 2021</p>
              </div>

              <div className="col text-right">
                <div className="step-and-provider">
                  <span className="header">477 Mapper</span>
                  {providerSelected && <p className={`subheader ${active === 3 ? 'mb-0' : 'mb-4'}`}>{providerSelected.providername}{" - "}{providerSelected.frn}</p>}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row mt-5">
              <div className="col text-center">
                <div className="logo mb-5">
                  <img src={Logo} />
                </div>
                <span className="header">477 Mapper</span>
                {providerSelected && <p className="subheader mb-4">{providerSelected.providername}{" - "}{providerSelected.frn}</p>}
              </div>
            </div>

            <Stepper
              active={active}
              setActive={onClickSetActive}
              method={method}
            />
          </>
        )}

        {active === 0 &&
          <div className="row">
            <div className="col text-center">
              <h2 className="mt-4 mb-2">The simple way to visualize 477 census blocks</h2>
              <p>Please choose a method below</p>
              <div className="choices">
                <div className={`choice ${method === 'csv' && 'selected'}`} onClick={() => methodSelect('csv')}>
                  <div className="icon-set">
                    <i className="fas fa-file-csv" />
                    <i className="fal fa-long-arrow-right" />
                    <i className="fas fa-globe-americas" />
                  </div>
                  <h5>Map your 477 data<br />(csv file upload)</h5>
                </div>

                <div className={`choice ${method === 'query' && 'selected'}`} onClick={() => methodSelect('query')}>
                  <div className="icon-set">
                    <i className="far fa-cloud" />
                    <i className="fal fa-long-arrow-right" />
                    <i className="fas fa-globe-americas" />
                  </div>
                  <h5>See and download 477 data and census blocks from the FCC's database.</h5>
                </div>
              </div>

              <button type="button" className="button w-100" onClick={goToStepOne}>Next Step</button>

              <div className="home-help">
                <a href="https://www.cooperative-networks.com/?page_id=21532" target="_blank" >Need help? Click Here</a>
              </div>

            </div>
          </div>
        }

        {active === 1 &&
          <div className="row">
            <div className="col text-center">
              {method === 'query' ? (
                <Search
                  onSearchInputChange={onSearchInputChange}
                  searchForItems={searchForItems}
                  fieldErrors={fieldErrors}
                />
              ) : (
                <Upload onUploadCSV={onUploadCSV} />
              )}
            </div>
          </div>
        }

        {active === 2 &&
          <ProviderList
            setProviderSelected={setProviderSelected}
            providerSelected={providerSelected}
            providers={results}
            onSelectProvider={onSelectProvider}
          />
        }

        <BillingModal
          billingModal={billingModal}
          setBillingModal={() => onChangeBillingModal()}
          totalBlocks={codes.length}
          onBuy={onBuy}
        />

        {isSearching && <Loading
          message={loadingText}
          progress={generated}
          isGenerating={isGenerating}
        />}
      </div>

      {active === 3 &&
        <Map
          map={map}
          setMap={setMap}
          downloadGeoJSON={downloadGeoJSON}
          onDownloadDrawnFeature={onDownloadDrawnFeature}
          onGenerate={onGenerate}
          fetchedGeoJSON={fetchedGeoJSON}
          drawnFeatures={drawnFeatures}
          setDrawnFeatures={setDrawnFeatures}
          onAddToCurrentView={onAddToCurrentView}
          censusDataAdded={censusDataAdded}
          studyDataAdded={studyDataAdded}
          moreResultsModal={moreResultsModal}
          codes={codes}
          theDraw={theDraw}
          setTheDraw={setTheDraw}
          onAddStudyLayer={onAddStudyLayer}
          hasStudyDataError={hasStudyDataError}
          onDownloadIntersectingFeature={onDownloadIntersectingFeature}
          onCSVDownloadIntersectingFeature={onCSVDownloadIntersectingFeature}
          blockLayerData={blockLayerData}
          onUploadCSVLayer={onUploadCSVLayer}
          onExportKMLIntersectingShapes={onExportKMLIntersectingShapes}
          mapboxgl={mapboxgl}
          onExportSHP={onExportSHP}
        />
      }
      <Checkout
        paymentModal={paymentModal}
        toggle={paymentModalToggle}
        onPayment={onPayment}
        totalAmount={totalAmount}
        onApplyCoupon={onApplyCoupon}
        couponApplied={couponApplied}
        setCouponApplied={setCouponApplied}
      />
      <Confirmation
        modal={confirmationModal}
        toggle={() => setConfirmationModal(!confirmationModal)}
      />
      <MoreResultModal
        modal={moreResultsModal}
        toggle={() => setMoreResultsModal(!moreResultsModal)}
      />
      <NoIntersection
        modal={hasIntersections}
        toggle={() => setHasIntersections(!hasIntersections)}
      />
      {/* <ImportCSV
                onChange={onFileChange}
                onImmport={onImmport}
                modal={modal}
                toggle={toggle}
            /> */}
      <ToastContainer />
    </>
  )
}

export default withRouter(Home);
