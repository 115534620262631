import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';

const Billing = (props) => {

    return (
        <Modal isOpen={props.modal} toggle={props.toggle} className="confirmation">
            <ModalBody >
                <p>An email has been sent to you with the link to download generated geojson</p>
                <button onClick={props.toggle}>Close</button>
            </ModalBody>

        </Modal >
    );
}

export default withRouter(Billing);