import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Search from './Containers/Search/index';
import Home from './Containers/Home/index'
import Checkout from './Containers/Checkout/index'

function App() {
  return (
    <Router>
      <Route path="/checkout" component={Checkout} />
      <Route path="/" exact component={Home} />
    </Router>
  );
}

export default App;
