import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import alasql from 'alasql';
import _ from 'lodash';
import tokml from "geojson-to-kml";
import axios from 'axios';


const Map = ({
    setMap,
    map,
    onDownloadDrawnFeature,
    downloadGeoJSON,
    onGenerate,
    fetchedGeoJSON,
    drawnFeatures,
    setDrawnFeatures,
    onAddToCurrentView,
    censusDataAdded,
    studyDataAdded,
    moreResultsModal,
    codes,
    theDraw,
    setTheDraw,
    onAddStudyLayer,
    hasStudyDataError,
    onDownloadIntersectingFeature,
    onCSVDownloadIntersectingFeature,
    blockLayerData,
    onUploadCSVLayer,
    onExportKMLIntersectingShapes,
    mapboxgl,
    onExportSHP
}) => {
    const mapContainer = useRef(null);
    const [isDrawing, setisDrawing] = useState(false);
    const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/satellite-v9');

    const styles = {
        width: "100%",
        height: "90vh",
        marginTop: "20px"
    };

    const renderMap = (data) => {
        if (fetchedGeoJSON && fetchedGeoJSON.features && !fetchedGeoJSON.features.length) {
            toast.error("No features found!")
            return
        }
        map.on('draw.create', newUpdateArea);
        map.on('draw.delete', newUpdateArea);
        map.on('draw.update', newUpdateArea);
        Object.entries(_.groupBy(fetchedGeoJSON.features, (each) => {
            return each.properties.techcode
        })).forEach(([techcode, features]) => {
            map.on('click', `generated-geojson-layer${techcode}`, function (e) {
                var coordinates = e.features[0].geometry.coordinates.slice();
                let features = e.features
                if (features.length === 1) {
                    const logrecno = e.features[0].properties.logrecno;
                    const provider_id = e.features[0].properties.provider_id;
                    const frn = e.features[0].properties.frn;
                    const providername = e.features[0].properties.providername;
                    const dbaname = e.features[0].properties.dbaname;
                    const holdingcompanyname = e.features[0].properties.holdingcompanyname;
                    const hoconum = e.features[0].properties.hoconum;
                    const hocofinal = e.features[0].properties.hocofinal;
                    const stateabbr = e.features[0].properties.stateabbr;
                    const blockcode = e.features[0].properties.blockcode;
                    const techcode = e.features[0].properties.techcode;
                    const consumer = e.features[0].properties.consumer;
                    const maxaddown = e.features[0].properties.maxaddown;
                    const maxadup = e.features[0].properties.maxadup;
                    const business = e.features[0].properties.business;

                    // Ensure that if the map is zoomed out such that multiple
                    // copies of the feature are visible, the popup appears
                    // over the copy being pointed to.
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }
                    if (!isDrawing)
                        new mapboxgl.Popup({ focusAfterOpen: false })
                            .setLngLat([e.lngLat.lng, e.lngLat.lat])
                            .setHTML(
                                '<div class="pop-holder">'
                                + '<div class="single-item"><strong>Logrecno: </strong>' + logrecno + '</div>'
                                + '<div class="single-item"><strong>Provider ID: </strong>' + provider_id + '</div>'
                                + '<div class="single-item"><strong>Frn: </strong>' + frn + '</div>'
                                + '<div class="single-item"><strong>Provider Name: </strong>' + providername + '</div>'
                                + '<div class="single-item"><strong>DBA Name: </strong>' + dbaname + '</div>'
                                + '<div class="single-item"><strong>Holding Company Name: </strong>' + holdingcompanyname + '</div>'
                                + '<div class="single-item"><strong>HOCO Num: </strong>' + hoconum + '</div>'
                                + '<div class="single-item"><strong>HOCO Final: </strong>' + hocofinal + '</div>'
                                + '<div class="single-item"><strong>State Abbr: </strong>' + stateabbr + '</div>'
                                + '<div class="single-item"><strong>Block Code: </strong>' + blockcode + '</div>'
                                + '<div class="single-item"><strong>Tech Code: </strong>' + techcode + '</div>'
                                + '<div class="single-item"><strong>Consumer: </strong>' + consumer + '</div>'
                                + '<div class="single-item"><strong>Max Ad Down: </strong>' + maxaddown + '</div>'
                                + '<div class="single-item"><strong>Max Ad Up: </strong>' + maxadup + '</div>'
                                + '<div class="single-item"><strong>Business: </strong>' + business + '</div>'
                                + '</div>'
                            )
                            .addTo(map);
                } else {
                    let html = `<ul class="popup-pagination"><li><a href="#page0">1</a></li><li><a href="#page1">2</a></li></ul>`
                    html += `<div class="popup-main">`
                    features.forEach((f, i) => {
                        html += `<div id="page${i}" class="page">`
                        html += '<div class="single-item"><strong>Logrecno: </strong>' + f.properties.logrecno + '</div>'
                            + '<div class="single-item"><strong>Provider ID: </strong>' + f.properties.provider_id + '</div>'
                            + '<div class="single-item"><strong>Frn: </strong>' + f.properties.frn + '</div>'
                            + '<div class="single-item"><strong>Provider Name: </strong>' + f.properties.providername + '</div>'
                            + '<div class="single-item"><strong>DBA Name: </strong>' + f.properties.dbaname + '</div>'
                            + '<div class="single-item"><strong>Holding Company Name: </strong>' + f.properties.holdingcompanyname + '</div>'
                            + '<div class="single-item"><strong>HOCO Num: </strong>' + f.properties.hoconum + '</div>'
                            + '<div class="single-item"><strong>HOCO Final: </strong>' + f.properties.hocofinal + '</div>'
                            + '<div class="single-item"><strong>State Abbr: </strong>' + f.properties.stateabbr + '</div>'
                            + '<div class="single-item"><strong>Block Code: </strong>' + f.properties.blockcode + '</div>'
                            + '<div class="single-item"><strong>Tech Code: </strong>' + f.properties.techcode + '</div>'
                            + '<div class="single-item"><strong>Consumer: </strong>' + f.properties.consumer + '</div>'
                            + '<div class="single-item"><strong>Max Ad Down: </strong>' + f.properties.maxaddown + '</div>'
                            + '<div class="single-item"><strong>Max Ad Up: </strong>' + f.properties.maxadup + '</div>'
                            + '<div class="single-item"><strong>Business: </strong>' + f.properties.business + '</div>'
                        html += `</div>`
                    })
                    html += `</div>`
                    new mapboxgl.Popup({ focusAfterOpen: false })
                        .setLngLat([e.lngLat.lng, e.lngLat.lat])
                        .setHTML(html)
                        .addTo(map);
                }
            });

            // Change the cursor to a pointer when the mouse is over the places layer.
            map.on('mouseenter', `generated-geojson-layer${techcode}`, function () {
                map.getCanvas().style.cursor = 'pointer';
            });

            // Change it back to a pointer when it leaves.
            map.on('mouseleave', `generated-geojson-layer${techcode}`, function () {
                map.getCanvas().style.cursor = '';
            });
        })

        return () => map.remove();
    }

    const newUpdateArea = (e) => {
        var data = theDraw.getAll();
        setDrawnFeatures(data)
    }

    const initializeMap = ({ setMap, mapContainer }) => {
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapStyle, // stylesheet location
            center: [-94.401622, 45.813486],
            zoom: 7
        });

        map.on("load", () => {
            let draw = new MapboxDraw({
                defaultMode: 'draw_polygon',
                controls: {
                    point: false,
                    line_string: false,
                    polygon: true,
                    trash: true,
                    combine_features: false,
                    uncombine_features: false
                }
            })
            setTheDraw(draw);
            if (drawnFeatures) {
                map.addControl(draw, 'top-left');
                draw.set(drawnFeatures)
            }

            setMap(map);
            map.addSource("blocks", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            });

            map.addLayer({
                id: "blocks-fill",
                source: "blocks",
                type: "fill",
                paint: {
                    "fill-color": 'rgba(0,0,0,0)',
                    "fill-outline-color": "#99ccca",
                }
            });
            map.addLayer({
                id: "blocks-fill-line",
                source: "blocks",
                type: "line",
                paint: {
                    "line-color": '#99ccca',
                    "line-width": 2,
                }
            });
            map.addSource("study", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: []
                }
            });

            map.addLayer({
                id: "study-fill",
                source: "study",
                type: "line",
                paint: {
                    "line-width": 2,
                    "line-color": "#e69c0a",
                }
            });

            map.on("click", "blocks-fill", (e) => {
                const p = e.features[0].properties;
                const message =
                    `<b class="p-3">Block Code ${p.GEOID}</b>`;

                new mapboxgl.Popup({ focusAfterOpen: false })
                    .setHTML(message)
                    .setLngLat(e.lngLat)
                    .addTo(map);

            });
            map.resize();

        });
    };

    useEffect(() => {
        if (!map) initializeMap({ setMap, mapContainer });
    }, []);
    useEffect(() => {
        if (map && !moreResultsModal)
            onGenerate()
    }, [map])
    useEffect(() => {
        if (Object.keys(fetchedGeoJSON).length > 0) {
            renderMap(fetchedGeoJSON);
        }
    }, [fetchedGeoJSON]);

    const testDraw = () => {
        setisDrawing(!isDrawing);
        try {
            if (!map.hasControl(theDraw))
                map.addControl(theDraw, 'top-left');
        } catch (e) {
            // theDraw.changeMode('draw_polygon')
        }
    }
    const onExport = () => {
        console.log(fetchedGeoJSON, 'fetchedGeoJSON')
        let mapped = fetchedGeoJSON.features.map(c => ({
            'LogRecNo': c.properties.logrecno,
            'Provider Id': c.properties.provider_id,
            'FRN': c.properties.frn,
            'Provider Name': c.properties.providername,
            'DBA name': c.properties.dbaname,
            'Holding company name ': c.properties.holdingcompanyname,
            'HocoNum': c.properties.hoconum,
            'HocoFinal': c.properties.hocofinal,
            'StateAbbr': c.properties.stateabbr,
            'BlockCode': c.properties.blockcode,
            'TechCode': c.properties.techcode,
            'Consumer': c.properties.consumer,
            'MaxAddDown': c.properties.maxaddown,
            'MaxAdUp': c.properties.maxadup,
            'Business': c.properties.business
        }))
        let filename = `${(new Date()).getTime()}.csv`
        alasql('SELECT * INTO CSV("' + filename + '",{headers:true, separator : ","}) FROM ?', [mapped]);
    }

    const onExportKML = async () => {
        const kml = tokml(fetchedGeoJSON);
        let filename = `${(new Date()).getTime()}.kml`
        const blob = new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    const updateMapStyle = () => {
        if (mapStyle === 'mapbox://styles/mapbox/streets-v11') {
            setMapStyle('mapbox://styles/mapbox/satellite-v9');
            map.setStyle('mapbox://styles/mapbox/satellite-v9');
        } else {
            setMapStyle('mapbox://styles/mapbox/streets-v11');
            map.setStyle('mapbox://styles/mapbox/streets-v11');
        }
    }
    useEffect(() => {
        if (map)
            initializeMap({ setMap, mapContainer })
    }, [mapStyle])
    const inputEl = useRef(null);
    const onClickUpload = (e) => {
        inputEl.current.click();
    }

    return (
        <div className={`map-container ${isDrawing ? 'popup-hidden' : ''}`}  >
            <div className="help">
                <div className="question">
                    <i class="fas fa-question"></i>
                </div>
                <a href="https://www.cooperative-networks.com/?page_id=21532" target="_blank">Need Help?  Click here</a>
            </div>
            <div className="buttons">
                <div className="button-group">
                    <span>VIEW</span>
                    <button className="geojson" onClick={updateMapStyle}>{mapStyle === 'mapbox://styles/mapbox/streets-v11' ? 'Satellite' : 'Street Map'} View</button>
                    <button className="geojson" onClick={onAddToCurrentView}>{!censusDataAdded ? 'Show/Update Census Block Boundaries' : <div className="spinner"></div>}</button>
                    <button className="geojson" onClick={onAddStudyLayer}>{!studyDataAdded ? hasStudyDataError ? 'Try again...' : 'Show/Update Study Area Boundaries' : <div className="spinner"></div>}</button>
                </div>
                <hr />
                <div className="button-group">
                    <span>ADD DATA</span>
                    <button className="geojson" onClick={onClickUpload}>Upload CSV
                        <input type="file" hidden ref={inputEl} onChange={onUploadCSVLayer} accept=".csv" />
                    </button>
                </div>
                <hr />
                <div className="button-group">
                    <span>DRAW</span>
                    <button className={`geojson ${isDrawing ? 'is-drawing' : ''}`} onClick={testDraw}>Draw Custom Shape</button>
                </div>
                <hr />
                <div className="button-group">
                    <span>EXPORT DATA</span>
                    <button className="feature" onClick={onExport}>Download 477 (CSV)</button>
                    <button className="feature" onClick={downloadGeoJSON}>Download 477 Shapes(GeoJSON)</button>
                    <button className="feature" onClick={onExportKML}>Download 477 Blocks (KML)</button>
                    <button className="feature" onClick={onExportSHP}>Download 477 Blocks (SHP)</button>
                    <button className="feature" onClick={onDownloadDrawnFeature}>Download Custom Shape</button>
                    <button className="feature" onClick={onDownloadIntersectingFeature} disabled={!blockLayerData || !drawnFeatures}>Download Intersecting Census Blocks (GeoJSON)</button>
                    <button className="feature" onClick={onCSVDownloadIntersectingFeature} disabled={!blockLayerData || !drawnFeatures}>Download Intersecting Census Blocks(csv)</button>
                    <button className="feature" onClick={onExportKMLIntersectingShapes} disabled={!blockLayerData || !drawnFeatures}>Download Intersecting Census Blocks(KML)</button>
                </div>
            </div>
            <div ref={el => (mapContainer.current = el)} style={styles} />
        </div>
    )
}

export default Map;
