import React, { useState, useRef } from 'react';

const Upload = ({ onUploadCSV }) => {
  const inputEl = useRef(null);
  const onClick = (e) => {
    inputEl.current.click();
  }
  return (
    <><form className="upload-form">
      {/* <div>Want to upload CSV instead?</div> */}
      <div className="uploader" onClick={onClick}>
        <i class="fa fa-upload"> </i>
        <span className="title">Upload CSV</span>
        <input type="file" hidden ref={inputEl} onChange={onUploadCSV} accept=".csv" />
      </div>

    </form>
      <h6 style={{
        fontSize: '0.8rem'
      }}>Please convert excel scientific notation to number with no decimal and include column headers provided with the template.</h6>
      <p>Click <a href="https://geojson-assets.s3.us-east-2.amazonaws.com/sample-json.csv">here</a> to download sample format</p>
    </>)
}

export default Upload;
